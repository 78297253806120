import styled from "styled-components";

import vars from "variables";

const Root = styled.div`
  li {
    width: 650px;
    margin: 12px 0;
    padding: 12px;
    display: flex;
    cursor: pointer;
    align-items: center;
    background: rgb(25, 26, 50);
    border-radius: 7px;
  }

  li:hover {
    background: rgb(18, 19, 37);
  }

  li:hover img {
    background: rgb(12, 14, 36);
  }

  strong {
    margin-left: 20px;
    font-size: 1.4rem;
    font-weight: 700;
    letter-spacing: 0.5px;
  }

  img {
    width: 60px;
    margin-left: auto;
    padding: 12px;
    background: ${vars.colors.marineBlue};
    border-radius: 7px;
  }

  ${vars.desktop.mediaQuery} {
    .connect {
      padding: 20px 60px;
      font-size: 0.775rem;
    }
  }

  ${vars.mobile.mediaQuery} {
    .connect {
      padding: 4px 9px;
      font-size: 0.585rem;
      border-radius: 5px;
    }
  }
`;

const Message = styled.div`
  &.error {
    text-align: center;
  }

  li {
    display: flex;
    cursor: pointer;
    align-items: center;
    background: rgb(25, 26, 50);
  }

  li.hr {
    height: 1px;
    padding: 0;
    cursor: default;
    display: block;
    line-height: 1px;
    text-align: center;
    background: rgb(51, 53, 80);
  }

  li.hr:hover {
    cursor: default;
    background: rgb(51, 53, 80);
  }

  li.hr span {
    padding: 0 12px;
    display: inline-block;
    background: rgb(33, 35, 65);
  }

  li:hover {
    background: rgb(18, 19, 37);
  }

  li:hover img {
    background: #e9e9e9;
  }

  strong {
    font-weight: 700;
    letter-spacing: 0.5px;
  }

  strong span {
    color: ${vars.colors.grey};
    font-weight: normal;
    font-size: 0.75rem;
    display: block;
  }

  img {
    margin-left: auto;
    background: ${vars.colors.white};
    border-radius: 7px;
  }

  ${vars.desktop.mediaQuery} {
    &.error {
      width: 600px;
    }

    &.error h2 {
      padding: 10px 0 30px;
      font-size: 3.6rem;
    }

    &.error .button {
      margin-bottom: -20px;
    }

    li {
      width: 650px;
      margin: 12px 0;
      padding: 12px;
      border-radius: 7px;
    }

    li.hr {
      width: 662px;
      margin: 40px 6px;
    }

    li.hr span {
      font-size: 1.15rem;
    }

    strong {
      margin-left: 20px;
      font-size: 1.4rem;
    }

    img {
      width: 60px;
      max-height: 60px;
      padding: 12px;
    }
  }

  ${vars.mobile.mediaQuery} {
    &.error {
      width: calc(100vw - 70px);
    }

    &.error h2 {
      padding: 10px 0 30px;
      font-size: 1.9rem;
    }

    &.error .button {
      margin-bottom: 30px;
    }

    li {
      margin: 9px 0;
      padding: 15px 18px 15px 6px;
      border-radius: 5px;
    }

    li.hr {
      width: calc(100% - 12px);
      margin: 30px 6px;
    }

    li.hr span {
      font-size: 0.8rem;
    }

    strong {
      margin-left: 10px;
      font-size: 0.85rem;
    }

    strong span {
      margin-top: 5px;
      padding-right: 14px;
      font-size: 0.7rem;
      line-height: 1rem;
    }

    img {
      width: 25px;
      padding: 5px;
      flex-shrink: 0;
    }
  }
`;

export { Root, Message };
