import React from "react";
import { Root } from "./style";

import { Link } from "gatsby";

import JoinTheCommunities from "components/Common/JoinTheCommunities";
import RawHTML from "components/Common/RawHTML";

import walletImage from "assets/Claim/Page/Claimed/wallet.svg";

import { useWeb3 } from "web3";
import { formatNumber, useSiteData } from "utils";

import vars from "variables";

const Claimed = ({ rewards }) => {
  const { wallet, reinvestTokens } = useWeb3();

  const {
    claim: { claimedSubtitle, claimedTitle, claimedBNBText, claimedHODLText },
  } = useSiteData().pages;

  const claimedTOKENText = claimedHODLText
    .replace(/HODL/g, reinvestTokens[rewards.token].label)
    .replace("$$", "$");

  let host, url;

  if (typeof window !== "undefined") {
    host = window.location.protocol + "//" + window.location.host;
    url =
      window.location.hostname === "localhost"
        ? `${host}${window.location.pathname}`
        : window.location.href;
  }

  const text = `${claimedTitle} I have claimed my daily #HODL / #BNB reward via @HODL_OFFICIAL :) Check it out! #passiveincome`;

  const toURL = (url, params = {}) => {
    const queryString = Object.keys(params)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      )
      .join("&");
    return url + "?" + queryString;
  };

  return (
    <Root>
      <div className="header">
        <img src={walletImage} />
        <h4>
          <span>{claimedSubtitle}</span>
        </h4>
        <h1>{claimedTitle}</h1>
        {rewards.percentageBNB > 0 ? (
          <div>
            <RawHTML
              className="claimed"
              html={claimedBNBText.replace(
                /\{PERCENTAGE\}/g,
                `<span>${rewards.percentageBNB}%</span>`
              )}
            />
            <h3>≈ {rewards.rewardBNB} BNB</h3>
          </div>
        ) : (
          ""
        )}
        {rewards.percentageTOKEN > 0 ? (
          <div>
            <RawHTML
              className="claimed"
              html={claimedTOKENText.replace(
                /\{PERCENTAGE\}/g,
                `<span>${rewards.percentageTOKEN}%</span>`
              )}
            />
            <h3>
              ≈ {formatNumber(rewards.rewardTOKEN)}{" "}
              {reinvestTokens[rewards.token].label}{" "}
            </h3>
          </div>
        ) : (
          ""
        )}
        <a className="button back blue" href={vars.pages.claim}>
          Go Back
        </a>
        <a
          className="button share blue"
          target="_blank"
          rel="noreferrer"
          href={toURL("https://twitter.com/intent/tweet", { text, url })}
        >
          Share
        </a>
        <div className="status">
          <label>Next Collection:</label>
          <p>{wallet.nextClaimDate}</p>
        </div>
      </div>
      <div className="body-width">
        <JoinTheCommunities />
      </div>
    </Root>
  );
};

export default Claimed;
