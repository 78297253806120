import React, { useEffect, useRef } from "react";
import { Root } from "./style";

import { useState } from "@hookstate/core";

import Connect from "./Menu/Connect";
import Wallet from "./Menu/Wallet";

import Connected from "./Page/Connected";
import Claimed from "./Page/Claimed";
import Disconnected from "./Page/Disconnected";

import LoadingOverlay from "components/Common/LoadingOverlay";

import { localStorageSet } from "utils";
import vars from "variables";
import { useWeb3, INJECTED } from "web3";

const Web3 = ({ location }) => {
  const { activate, wallet } = useWeb3();

  const loadingOverlay = useRef();

  const state = useState({
    loading: true,
    claimed: null,
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const address = searchParams.get("a");

    if (address) {
      localStorageSet("provider", INJECTED);
      window.sessionStorage.setItem("walletAddress", address);
      const style = document.createElement("style");
      style.innerHTML = `
        header,
        footer,
        .your-account,
        .compact .scalable .open,
        .join-the-communities,
        a[href] {
          display: none !important;
        }
        html, body {
          background-color: ${vars.colors.black} !important;
        }
      `;
      document.head.appendChild(style);
    }

    loadingOverlay.current.show("Getting wallet info");

    activate().then(() => {
      state.loading.set(false);
      loadingOverlay.current.hide();
    });
  }, []);

  return (
    <Root>
      <LoadingOverlay usedRef={loadingOverlay} />
      <div className={state.loading.get() ? "loading" : ""}>
        {wallet ? <Wallet /> : <Connect />}
        {wallet ? (
          state.claimed.get() ? (
            <Claimed rewards={state.claimed.get()} />
          ) : (
            <Connected onClaim={state.claimed.set} />
          )
        ) : (
          <Disconnected />
        )}
      </div>
    </Root>
  );
};

export default Web3;
