import styled from "styled-components";

import vars from "variables";

import arrows from "assets/Claim/Page/Connected/Slider/arrows.svg";
import bnb from "assets/Claim/Page/Connected/Slider/bnb.svg";
import hodl from "assets/Claim/Page/Connected/Slider/hodl.svg";
import hodlx from "assets/Common/Logo/hodlx.png";

import select from "assets/Claim/Page/Connected/Slider/select.svg";

const Root = styled.div`
  margin: 0 auto;
  text-align: left;
  background: rgb(13, 15, 34);

  span {
    color: ${vars.colors.grey};
  }

  .amount {
    display: flex;
    align-items: center;
    justify-items: center;
  }

  .amount label {
    color: ${vars.colors.white};
  }

  label {
    margin-left: auto;
    font-weight: bold;
    background: no-repeat left center;
  }

  label.select {
    margin-right: -12px;
    padding: 5px 34px 2px 7px;
    display: inline-block;
    color: ${vars.colors.black};
    background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position: right center;
    background-image: url(${select});
    border-radius: 25px;
    animation-name: pulse;
    animation-delay: 6s;
    animation-duration: 1.25s;
    animation-iteration-count: 10;
  }

  @keyframes pulse {
    0% {
      background-color: rgb(13, 15, 34);
    }
    50% {
      background-color: #1c214a;
    }
    100 {
      background-color: #1c214a;
    }
  }

  label.select:hover {
    cursor: pointer;
    background-color: #1c214a;
  }

  label.select span {
    margin-right: -12px;
    padding: 9px 14px 7px 39px;
    color: ${vars.colors.white};
    background-position: left center;
    background-repeat: no-repeat;
  }

  label.select .hodl {
    background-image: url(${hodl});
  }

  label.select .hodlx {
    background-image: url(${hodlx});
  }

  label.bnb {
    background-image: url(${bnb});
  }

  .slider {
    height: 4px;
    position: relative;
    background: ${vars.colors.purple};
  }

  .knob {
    cursor: grab;
    position: absolute;
    background: url(${arrows}) ${vars.colors.blue} no-repeat center center;
    border-radius: 4px;
  }

  .tooltip {
    margin: -61px -238px;
    padding: 13px 18px 11px 18px;
    display: inline-block;
    line-height: 175%;
    position: absolute;
    background-color: ${vars.colors.marineBlue};
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border-radius: 10px;
    opacity: 1 !important;
    &:after {
      position: absolute;
      content: "";
      width: 0px;
      height: 0px;
      border-top: 8px solid ${vars.colors.marineBlue};
      border-right: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 8px solid transparent;
      bottom: -16px;
      left: 50%;
    }
  }

  .tooltip.hide {
    display: none;
  }

  ${vars.desktop.mediaQuery} {
    & {
      width: 600px;
      padding: 30px 35px 75px;
      border-radius: 10px;
    }

    span {
      font-size: 1.2rem;
    }

    strong {
      font-size: 2.1rem;
    }

    label {
      margin-top: 4px;
      padding-left: 38px;
      font-size: 1.1rem;
      letter-spacing: 0.5px;
    }

    label.bnb {
      padding-left: 40px;
      font-size: 1.2rem;
    }

    label.select span {
      background-size: 26px 26px;
    }

    .slider {
      margin: 40px 0;
    }

    .knob {
      width: 55px;
      height: 55px;
      top: -25px;
      box-shadow: 15px 0 10px 10px rgba(13, 15, 34, 0.5);
    }
  }

  ${vars.mobile.mediaQuery} {
    & {
      width: calc(100% - 120px);
      padding: 20px 25px 52px;
      border-radius: 8px;
    }

    span {
      font-size: 0.8rem;
    }

    strong {
      font-size: 1.2rem;
      font-weight: 600;
    }

    label {
      margin-top: 5px;
      padding-left: 22px;
      font-size: 0.9rem;
      font-weight: 600;
      background-size: 15px;
    }

    label.select {
      margin-right: -15px;
      background-size: 1.45rem 1.45rem;
    }

    label.select span {
      padding: 9px 2px 7px 23px;
      background-size: 1rem 1rem;
    }

    .tooltip {
      margin: -40px -45px;
      padding: 7px 7px 5px 7px;
      border-radius: 5px;
      :after {
        left: 50px;
      }
    }

    .slider {
      margin: 32px 0;
    }

    .knob {
      width: 42px;
      height: 42px;
      top: -18px;
      box-shadow: 10px 0 5px 5px rgba(13, 15, 34, 0.5);
      background-size: 20px;
    }
  }
`;

export { Root };
