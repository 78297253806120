import React from "react";
import { Root, Message } from "./style";

import Modal from "components/Common/Modal";

import { useSiteData } from "utils";

const Select = ({ usedRef, onSelect, title, options }) => {
  const {
    claim: { selectHODLText, selectHODLXText },
  } = useSiteData().pages;

  return (
    <Root>
      <Modal usedRef={usedRef}>
        <Message>
          <h3>{title}</h3>
          <ul>
            {options.map(({ label, value, image }) => (
              <li
                key={value}
                onClick={() => {
                  usedRef.current.hide();
                  onSelect(value);
                }}
              >
                <strong>
                  {label}
                  <span>
                    {{ hodl: selectHODLText, hodlx: selectHODLXText }[value]}
                  </span>
                </strong>
                <img src={image} />
              </li>
            ))}
          </ul>
        </Message>
      </Modal>
    </Root>
  );
};

export default Select;
